<template>
    <component :is="template">
        <v-layout class="overflow-visible">
            <!-- <v-flex v-show="!$vuetify.breakpoint.mobile" shrink class="sidebar">
                <v-card>
                    <v-card-title>
                        <router-link to="?" class="subtitle-1 primary--text">모바일상품권</router-link>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text class="subtitle-1">
                        <ul>
                            <li class="py-2"><router-link to="?category-code=book" class="black--text">도서문화상품권</router-link></li>
                            <li class="py-2"><router-link to="?category-code=cultureland" class="black--text">컬쳐랜드</router-link></li>
                            <li class="py-2"><router-link to="?category-code=afreecatv" class="black--text">아프리카TV 별풍선</router-link></li>
                            <li class="py-2"><router-link to="?category-code=google-giftcard" class="black--text">구글 기프트카드</router-link></li>
                            <li class="py-2"><router-link to="?category-code=food" class="black--text">외식·커피·케익 쿠폰</router-link></li>
                            <li class="py-2"><router-link to="?category-code=life" class="black--text">주유·편의점·생활 쿠폰</router-link></li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-flex> -->
            <!-- <v-flex grow :class="{ 'sidebar-content': !$vuetify.breakpoint.mobile }">
                <giftcard-list :filter="filter">
                    <template slot="header">
                        <span v-if="!categoryCode" class="headline">모바일상품권</span>
                        <span v-if="categoryCode == 'book'" class="headline">도서문화상품권</span>
                        <span v-if="categoryCode == 'cultureland'" class="headline">컬쳐랜드</span>
                        <span v-if="categoryCode == 'afreecatv'" class="headline">아프리카TV 별풍선</span>
                        <span v-if="categoryCode == 'google-giftcard'" class="headline">구글 기프트카드</span>
                        <span v-if="categoryCode == 'food'" class="headline">외식·커피·케익 쿠폰</span>
                        <span v-if="categoryCode == 'life'" class="headline">주유·편의점·생활 쿠폰</span>
                    </template>
                </giftcard-list>
            </v-flex> -->
            <v-flex>
                <giftcard-list :filter="filter">
                    <template slot="header">
                        <span>포인트몰</span>
                    </template>
                </giftcard-list>
            </v-flex>
        </v-layout>
    </component>
</template>

<script>
import GiftcardList from "@/components/client/gshop/giftcard/giftcard-list.vue";
export default {
    components: {
        GiftcardList
    },
    computed: {
        levelCode(){
            return this.$store.state.levelCode;
        },
        template(){
            return () => import(`@/templates/${this.$theme.pages.gshop}`);
        },
        categoryCode(){
            return this.$route.query["category-code"];
        },
        filter(){
            return {
                "type": "giftcard",
                "category-code": "company",
                "levelCode": "vip"
            }
        }
    },
    methods: {
        init(){
            if(this.levelCode != `vip`){
                alert("기업회원만 이용 가능합니다");
                this.$router.go(-1);
                return;
            }
        }
    },
    mounted(){
        this.init();
    }
}
</script>

<style scoped>
.sidebar {
    width: 250px;
}
.sidebar-content {
    max-width: calc(100% - 250px);
}
ul {
    list-style-type: none;
    padding-left: 0;
}
</style>
<template>
    <v-card color="transparent" elevation="0" :to="`/gshop/giftcards/${giftcard._id}`" class="giftcard">
        <div style="border: 1px solid rgb(224, 224, 224);">
            <v-img :src="giftcard?.thumb?.path || giftcard?.thumb?.src" />
        </div>
        <div class="mt-2 gift-title">{{ giftcard.name }}</div>
        <div class="mt-2 mb-8 gift-subtitle primary--text">권종 선택구매</div>
    </v-card>
</template>

<script>
export default {
    props: ["giftcard"]
}
</script>
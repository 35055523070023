var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1200"
    }
  }, [_c('v-layout', {
    staticClass: "justify-center justify-sm-start"
  }, [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-card-title', {
    staticClass: "headline d-flex d-sm-block justify-center"
  }, [_c('span', {
    staticClass: "py-4 pl-sm-4"
  }, [_c('b', [_vm._t("header")], 2)])])], 1)], 1), _c('v-layout', {
    staticClass: "mt-4 mx-n2",
    attrs: {
      "wrap": ""
    }
  }, [_vm._l(_vm.giftcards, function (giftcard, index) {
    return [_c('v-flex', {
      key: index,
      staticClass: "px-2",
      attrs: {
        "xs6": "",
        "md3": ""
      }
    }, [_c('giftcard-list-item', {
      attrs: {
        "giftcard": giftcard
      }
    })], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$giftcard, _vm$giftcard$thumb, _vm$giftcard2, _vm$giftcard2$thumb;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "giftcard",
    attrs: {
      "color": "transparent",
      "elevation": "0",
      "to": `/gshop/giftcards/${_vm.giftcard._id}`
    }
  }, [_c('div', {
    staticStyle: {
      "border": "1px solid rgb(224, 224, 224)"
    }
  }, [_c('v-img', {
    attrs: {
      "src": ((_vm$giftcard = _vm.giftcard) === null || _vm$giftcard === void 0 ? void 0 : (_vm$giftcard$thumb = _vm$giftcard.thumb) === null || _vm$giftcard$thumb === void 0 ? void 0 : _vm$giftcard$thumb.path) || ((_vm$giftcard2 = _vm.giftcard) === null || _vm$giftcard2 === void 0 ? void 0 : (_vm$giftcard2$thumb = _vm$giftcard2.thumb) === null || _vm$giftcard2$thumb === void 0 ? void 0 : _vm$giftcard2$thumb.src)
    }
  })], 1), _c('div', {
    staticClass: "mt-2 gift-title"
  }, [_vm._v(_vm._s(_vm.giftcard.name))]), _c('div', {
    staticClass: "mt-2 mb-8 gift-subtitle primary--text"
  }, [_vm._v("권종 선택구매")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
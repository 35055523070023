<template>
    <v-responsive max-width="1200" class="mx-auto">
        <v-layout class="justify-center justify-sm-start">
            <v-flex shrink>
                <v-card-title class="headline d-flex d-sm-block justify-center">
                    <span class="py-4 pl-sm-4"><b><slot name="header" /></b></span>
                </v-card-title>
            </v-flex>
        </v-layout>
        
        <!-- 상품권 리스팅 -->
        <v-layout class="mt-4 mx-n2" wrap>
            <template v-for="giftcard, index in giftcards">
                <v-flex xs6 md3 class="px-2" :key="index">
                    <giftcard-list-item :giftcard="giftcard" />
                </v-flex>
            </template>
        </v-layout>
    </v-responsive>
</template>

<script>
import api from "@/api";
import GiftcardListItem from "./giftcard-list-item.vue";
export default {
    components: {
        GiftcardListItem
    },
    props: ["filter", "to"],
    data(){
        return {
            limit: 20,

            giftcards: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            let { giftcards } = await api.v1.gshop.giftcards.gets({
                headers: {
                    limit: this.limit
                },
                params: this.$props.filter
            });

            this.giftcards = giftcards;
        }
    },
    watch: {
        "$props.filter": {
            deep: true,
            handler(){ this.search(); }
        }
    }
}
</script>